<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="header"
          :items="rfcards"
          :loading="loading"
          loading-text="Загрузка... Подождите"
          :items-per-page="25"
          :footer-props="{
            'items-per-page-options': [15, 25, 50, 100, -1],
          }"
        >
          <template v-slot:top>
            <v-toolbar dense flat>
              <v-text-field
                v-model="search"
                hide-details
                label="Поиск по UID"
                prepend-icon="mdi-magnify"
                single-line
              ></v-text-field>

              <!--          <v-btn icon @click="add_item">-->
              <!--            <v-icon>mdi-plus-box</v-icon>-->
              <!--          </v-btn>-->
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="add_item"
                  >
                    mdi-plus-box
                  </v-icon>
                </template>
                <span>Добавить карту</span>
              </v-tooltip>
            </v-toolbar>
            <v-dialog v-model="dialog" max-width="500px" @click:outside="close">
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="6" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Название карты*"
                          hint="Название позволяют отличать карты"
                          :rules="[required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.uid"
                          :rules="[required]"
                          label="Номер карты*"
                          :readonly="editedIndex != -1"
                          hint="Уникальный номер карты"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="12">
                        <v-menu
                          v-model="date_select_menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.valid_until"
                              label="Активна до*"
                              prepend-icon="mdi-calendar"
                              readonly
                              :rules="[required]"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.valid_until"
                            locale="ru-RU"
                            first-day-of-week="1"
                            @input="date_select_menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="12">
                        <v-checkbox
                          v-model="editedItem.available"
                          label="Карта активна"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-container>
                    <v-row>
                      <v-col class="my-auto">
                        <h3>Доступные станции</h3>
                      </v-col>
                      <v-col>
                        <v-btn icon @click="add_point">
                          <v-icon>mdi-plus-box</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Номер</th>
                            <th class="text-left">Адресс</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="cp in available_points" :key="cp.id">
                            <td>{{ cp.point_number }}</td>
                            <td>{{ cp.address }}</td>
                            <td>
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    small
                                    class="mr-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="remove_cp(cp)"
                                  >
                                    mdi-delete
                                  </v-icon>
                                </template>
                                <span>Удалить привязку к станции</span>
                              </v-tooltip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red darken-1" text @click="close">
                    Отмена
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save">
                    Сохранить
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="point_dialog" max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Добавить станцию</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Номер</th>
                            <th class="text-left">Адресс</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="cp in points"
                            :key="cp.id"
                            @click="select_station(cp)"
                          >
                            <td>{{ cp.point_number }}</td>
                            <td>{{ cp.address }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.available="{ item }">
            <v-checkbox v-model="item.available" readonly></v-checkbox>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Редактировать</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small v-bind="attrs" v-on="on"> mdi-delete </v-icon>
              </template>
              <span>{{ remove_tooltip(item) }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "RfcardList",
  data() {
    return {
      search: "",
      editedIndex: -1,
      dialog: false,
      point_dialog: false,
      required: (v) => !!v || "Обязателено для заполнения",
      date_select_menu: false,
      editedItem: {
        name: "",
        valid_until: "",
        uid: "",
        available: false,
        available_on: [],
      },
      defaultItem: {
        name: "",
        valid_until: "",
        uid: "",
        available: false,
        available_on: [],
      },
      options: {
        sortBy: ["id"],
        sortDesc: [false],
      },
    };
  },
  created() {
    this.$store.dispatch("list_rfcard", this.options);
    this.$store.dispatch("list_points", { page: 1, itemsPerPage: 800 });
  },
  watch: {
    params: {
      handler() {
        this.$store.dispatch("list_rfcard", this.params);
      },
      deep: true,
    },
  },
  computed: {
    rfcards() {
      return this.$store.getters.RFCARDS;
    },
    points() {
      return this.$store.getters.POINTS;
    },
    loading() {
      return this.$store.getters.RFCARD_LOADING;
    },
    available_points() {
      return this.points.filter((point) => {
        return this.editedItem.available_on.includes(point.id);
      });
    },
    params() {
      return {
        ...this.options,
        query: this.search,
      };
    },
    header() {
      return [
        {
          text: "Id",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Название",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Идентификатор",
          align: "start",
          sortable: true,
          value: "uid",
        },
        {
          text: "Активна",
          align: "start",
          sortable: true,
          value: "available",
        },
        {
          text: "Срок действия",
          align: "start",
          sortable: true,
          value: "valid_until",
        },
        { text: "", value: "actions", sortable: false },
      ];
    },
    formTitle() {
      return this.editedIndex === -1 ? "Добавить карту" : "Изменить карту";
    },
  },
  methods: {
    add_item() {
      this.editItem(this.defaultItem);
    },
    add_point() {
      this.point_dialog = true;
    },
    select_station(cp) {
      if (this.editedItem.available_on.includes(cp.id)) {
        this.point_dialog = false;
      }
      this.editedItem.available_on.push(cp.id);
      this.point_dialog = false;
    },
    editItem(item) {
      this.dialog = true;
      this.editedIndex = this.rfcards.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // this.operates_from_enabled = false;
      // this.$nextTick(() => {
      //   this.operates_from_enabled = true;
      // });
    },
    close() {
      this.dialog = false;
      this.defaultItem.available_on = [];
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.available_on = [];
        this.editedIndex = -1;
        // this.operates_from_enabled = false;
        // this.$nextTick(() => {
        //   this.operates_from_enabled = true;
        // });
      });
    },
    remove_tooltip() {
      // if (item.charge_points.length > 0) {
      //   return "Нельзя удалять действующие тарифы";
      // }
      return "Удалить карту";
    },
    remove_cp(cp) {
      const cp_index = this.editedItem.available_on.indexOf(cp.id);
      if (cp_index > -1) {
        this.editedItem.available_on.splice(cp_index, 1);
      }
    },
    save() {
      if (this.editedIndex > -1) {
        this.$store.dispatch("update_rfcard", this.editedItem).then(() => {
          this.$store.dispatch("list_rfcard", this.params);
        });
      } else {
        this.$store.dispatch("create_rfcard", this.editedItem).then(() => {
          this.$store.dispatch("list_rfcard", this.params);
        });
      }
      this.close();
    },
  },
};
</script>

<style scoped></style>
