var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.rfcards,"loading":_vm.loading,"loading-text":"Загрузка... Подождите","items-per-page":25,"footer-props":{
          'items-per-page-options': [15, 25, 50, 100, -1],
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-text-field',{attrs:{"hide-details":"","label":"Поиск по UID","prepend-icon":"mdi-magnify","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":_vm.add_item}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus-box ")])]}}])},[_c('span',[_vm._v("Добавить карту")])])],1),_c('v-dialog',{attrs:{"max-width":"500px"},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Название карты*","hint":"Название позволяют отличать карты","rules":[_vm.required]},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"rules":[_vm.required],"label":"Номер карты*","readonly":_vm.editedIndex != -1,"hint":"Уникальный номер карты"},model:{value:(_vm.editedItem.uid),callback:function ($$v) {_vm.$set(_vm.editedItem, "uid", $$v)},expression:"editedItem.uid"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Активна до*","prepend-icon":"mdi-calendar","readonly":"","rules":[_vm.required]},model:{value:(_vm.editedItem.valid_until),callback:function ($$v) {_vm.$set(_vm.editedItem, "valid_until", $$v)},expression:"editedItem.valid_until"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date_select_menu),callback:function ($$v) {_vm.date_select_menu=$$v},expression:"date_select_menu"}},[_c('v-date-picker',{attrs:{"locale":"ru-RU","first-day-of-week":"1"},on:{"input":function($event){_vm.date_select_menu = false}},model:{value:(_vm.editedItem.valid_until),callback:function ($$v) {_vm.$set(_vm.editedItem, "valid_until", $$v)},expression:"editedItem.valid_until"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-checkbox',{attrs:{"label":"Карта активна"},model:{value:(_vm.editedItem.available),callback:function ($$v) {_vm.$set(_vm.editedItem, "available", $$v)},expression:"editedItem.available"}})],1)],1)],1),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"my-auto"},[_c('h3',[_vm._v("Доступные станции")])]),_c('v-col',[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.add_point}},[_c('v-icon',[_vm._v("mdi-plus-box")])],1)],1)],1),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Номер")]),_c('th',{staticClass:"text-left"},[_vm._v("Адресс")]),_c('th')])]),_c('tbody',_vm._l((_vm.available_points),function(cp){return _c('tr',{key:cp.id},[_c('td',[_vm._v(_vm._s(cp.point_number))]),_c('td',[_vm._v(_vm._s(cp.address))]),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.remove_cp(cp)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Удалить привязку к станции")])])],1)])}),0)]},proxy:true}])})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Сохранить ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.point_dialog),callback:function ($$v) {_vm.point_dialog=$$v},expression:"point_dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Добавить станцию")])]),_c('v-card-text',[_c('v-container',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Номер")]),_c('th',{staticClass:"text-left"},[_vm._v("Адресс")])])]),_c('tbody',_vm._l((_vm.points),function(cp){return _c('tr',{key:cp.id,on:{"click":function($event){return _vm.select_station(cp)}}},[_c('td',[_vm._v(_vm._s(cp.point_number))]),_c('td',[_vm._v(_vm._s(cp.address))])])}),0)]},proxy:true}])})],1)],1)],1)],1)]},proxy:true},{key:"item.available",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":""},model:{value:(item.available),callback:function ($$v) {_vm.$set(item, "available", $$v)},expression:"item.available"}})]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Редактировать")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.remove_tooltip(item)))])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }